<template>
    <section class="structure-main">
        <div class="white-container">
            <h5>Нормативные правовые и иные акты в сфере противодействия коррупции</h5>
            <tricolor-line />
            <div class="block-structure" v-for="item of listNav" :key="'id_'+item.id">
                <p :id="item.id" class="title_structure">{{item.title}}</p>
                <div v-if="item.type=='list'">
                    <ul>
                        <li v-for="(index, i) of item.content" :key="i">
                            <router-link :to="index.link">{{index.text}}<span> </span></router-link>
                        </li>
                    </ul>
                </div>
                <div v-else-if="item.type=='download'" class="partner-info" v-for="(index,i) of item.content" :key="'download_'+i">
                    <div class="img_block"></div>
                    <div class="info_block">
                        <p>{{index.title}}</p>
                        <download-file :dataFile="{url: index.url, size: index.size, type: index.type}" class="download-file"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="white-container">
            <p class="title_structure">Навигация по странице</p>
            <a :href="'#'+item.id" v-for="item of listNav" :key="item.id">{{item.title}}</a>
        </div>
    </section>
</template>
<script>
import TricolorLine from '@/components/TricolorLine'
import DownloadFile from '@/components/DownloadFile'
export default {
  name: 'NormativeAct',
  components: {
    'tricolor-line': TricolorLine,
    'download-file': DownloadFile
  },
  data () {
    return {
      listNav: [
        {
          title: 'Федеральные законы',
          id: 'nav1',
          type: 'list',
          content: [
            {
              text: '«Трудовой кодекс Российской Федерации» от 30 декабря 2001 г. № 197-ФЗ (статья 349.2)',
              link: '#'
            },
            {
              text: 'Федеральный закон от 3 декабря 2012 г. №231-ФЗ «О внесении изменений в отдельные законодательные акты Российской Федерации в связи с принятием федерального закона «О контроле за соответствием расходов лиц, замещающих государственные должности, и иных лиц их доходам» ',
              link: '#'
            },
            {
              text: 'Федеральный закон от 3 декабря 2012 г. №230-ФЗ «О контроле за соответствием расходов лиц, замещающих государственные должности, и иных лиц их доходам»',
              link: '#'
            }
          ]
        },
        {
          title: 'Указы Президента Российской Федераци',
          id: 'nav2',
          type: 'download',
          content: [
            {
              title: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
              url: '#',
              size: '217',
              type: 'PDF'
            },
            {
              title: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
              url: '#',
              size: '217',
              type: 'PDF'
            }
          ]
        },
        {
          title: 'Постановления Правительства Российской Федерации',
          id: 'nav3',
          type: 'download',
          content: [
            {
              title: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
              url: '#',
              size: '217',
              type: 'PDF'
            },
            {
              title: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
              url: '#',
              size: '217',
              type: 'PDF'
            }
          ]
        },
        {
          title: 'Иные Нормативные Правовые Акты',
          id: 'nav4',
          type: 'download',
          content: [
            {
              title: 'Приказ Минтруда России от 7 октября 2013 г. № 530н «О требованиях к размещению и наполнению подразделов, посвященных вопросам противодействия коррупции, официальных сайтов федеральных государственных органов, центрального банка российской федерации, пенсионного фонда российской федерации, фонда социального страхования российской федерации, федерального фонда обязательного медицинского страхования, государственных корпораций (компаний), иных организаций, созданных на основании федеральных законов, и требованиях к должностям, замещение которых влечет за собой размещение сведений о доходах, расходах, об имуществе и обязательствах имущественного характера». Зарегистрирован в Минюсте России 25 декабря 2013 г., регистрационный № 30803',
              url: '#',
              size: '217',
              type: 'PDF'
            },
            {
              title: 'Об утверждении Перечня должностей федерального казенного учреждения «Аппарат Общественной палаты Российской Федерации», при назначении на которые граждане и при замещении которых работники обязаны представлять сведения о своих доходах, об имуществе и обязательствах имущественного характера, а также сведения о доходах, об имуществе и обязательствах имущественного характера своих супруги (супруга) и несовершеннолетних детей',
              url: '#',
              size: '217',
              type: 'PDF'
            }
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.structure-main{
    display: flex;
    justify-content: space-between;
    width: 90rem;
    margin: 0 auto;
    & .white-container:nth-child(1){
        width: calc(100% - 26.44rem - 2.19rem);
        margin: 0;
        padding: 2.5rem 2.94rem;
    }
    & .white-container:nth-child(2){
        width: 26.44rem;
        height: 100%;
        margin: 0 0 0 2.19rem;
        padding: 1.88rem;
    }
}
.white-container{
    display: flex;
    flex-direction: column;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.875rem;
}
.block-structure{ margin-top: 3.125rem; }
.block-structure:nth-of-type(2){ margin-top: 2rem;}
.title_structure{
    width: 100%;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    opacity: 0.8;
    padding-bottom: 0.5rem;
}
ul > li{
    list-style-type: disc;
    color: #246CB7;
    margin:  0.75rem 0 0 1rem;
}
a{
    font-size: 0.875rem;
    line-height: 1.38rem;
    color: #246CB7;
    margin-top: 0.75rem;
}
a>span{
    background: url('../../assets/icons/link_anticorrop.svg') center center no-repeat;
    background-size: .813rem;
    padding: .1rem 0.6rem;
    margin-left: .7rem;
}
///////////////////
.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.75rem 0 1.875rem 0;
}
.partner-info:last-child{
    margin-bottom: 0;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.info_block{
    margin-left: 1rem;
    width: calc(100% - 1rem - 2.82rem);
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
}
.download-file{
    margin-top: .97rem;
}
@media screen and (max-width: 768px){
    .structure-main{
        width: auto;
        margin: 0 .75rem;
        & .white-container:nth-child(1){
            width: 100%;
            margin: 0;
            padding: 1.86rem 1.25rem;
        }
        & .white-container:nth-child(2){ display: none; }
    }
    .download-file{
        margin-top: .75rem;
    }
}
@media screen and (max-width: 420px){
    .structure-main{
        width: 100%;
        margin: 0;
    }
    .structure_select{
        width: 100%;
    }
    .structure-main .white-container:nth-child(1){
        padding: 1.05rem .76rem;
    }
    ul > li {
        margin: .95rem 0 0 .76rem;
        font-size: .64rem;
    }
    .title_structure{
        padding: 0;
    }
    .margin_select{
        padding-top: 0.76rem;
        margin: 0;
        padding-left: 0;
    }
    .block-structure{
        margin-top: 1.53rem;
    }
    .structure_select-button{
        margin-top: 0.25rem;
    }
}
</style>
