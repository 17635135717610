<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
        <normative-act/>
    </section>
</template>
<script>
import NormativeAct from '@/components/antiCorruption/NormativeAct'
import BreadCrumb from '@/components/BreadCrumb'
export default {
  name: 'NormativeLegalAct',
  components: {
    'normative-act': NormativeAct,
    'bread-crumb': BreadCrumb
  },
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Противодействие коррупции',
          path: '/'
        },
        {
          title: 'Нормативные правовые Акты'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
